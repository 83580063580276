import React, { useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiConfirmModal,
} from "@elastic/eui"
import { navigate } from "gatsby-link"
import { useStore } from "../../stores"
import { observer } from "mobx-react-lite"
import {
  getBookingDateUIFormatted,
  getBookingTimeUIFormatted,
} from "../../entities/utilities"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import "../../styles/hailcx-badges.css"
import "../../styles/hailcx-spinners.css"

const MyOffersPage = () => {
  const { bookingStore, customerStore, waitlistStore } = useStore()
  const { booking, setBooking, setTimeSelected } = bookingStore
  const { customer } = customerStore
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)

  const data = {
    title: "New Offers",
    primaryContent: "Choose this time",
    secondaryContent: "Keep my current booking",
  }
    
  const [scrollBookingSlotToView, setScrollBookingSlotToView] = useState(true);

  const closeModal = () => setIsModalVisible(false)

  const handleReject = () => {
    waitlistStore.rejectOffer(waitlistStore.currentOffer?.offerId || "")
    setIsCancelled(true)
    closeModal()
    navigate(
      `/book/manage-booking?bookingNumber=${bookingStore.bookingNumber}&encryptedQuoteNumber=${customerStore.encryptedQuoteNumber}`
    )
  }

  let modal = (
    <EuiConfirmModal
      title="Are you sure?"
      onCancel={closeModal}
      onConfirm={handleReject}
      cancelButtonText="Go back"
      confirmButtonText="Confirm"
      defaultFocusedButton="confirm"
    >
      <p>You will not be able to see this offer again.</p>
    </EuiConfirmModal>
  )

  const handlePrimaryClick = () => {
    if (customer && booking) {
      setBooking({ ...booking, customerId: customer.id })
    }

    navigate("/book/confirm-rescheduled")
  }

  const handleSecondaryClick = () => {
    setIsModalVisible(true)
  }

  const handleBadgeClick1 = (id: string) => {
    waitlistStore.setSelectedOfferItem(id)
    bookingStore.bookTimeSlot
  }

  const handleBadgeClick = (
    id: string,
    repairDate: string,
    dropOffTime: string
  ) => {

    //don't scroll when user is selecting the slot; we want to scroll to view only when user comes back to this screen from another screen
    setScrollBookingSlotToView(false); 

    waitlistStore.setSelectedOfferItem(id)
    setTimeSelected(id)

    if (booking) {
      setBooking({
        ...booking,
        repairDate: repairDate,
        dropOffTime: dropOffTime,
      })
    }
  }

  const assignColor = (id: string) => {
    if (id === waitlistStore.selectedOfferItem) {
      return "success"
    } else {
      return "default"
    }
  }

  useEffect(() => {}, [waitlistStore.selectedOfferItem])

  return (
    <div>No longer in use with new version of waitlist.</div>
  )
}

export default observer(MyOffersPage)
